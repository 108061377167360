import { getBaseURL } from '../env'
import {
  AUTH_TOKEN,
  PROJECT_TAG,
} from '../constants'


const isAuthenticated = () => {
  return localStorage.getItem(AUTH_TOKEN) !== null
}

const getAuthToken = () => {
  return localStorage.getItem(AUTH_TOKEN)
}

const login = token => {
  localStorage.setItem(AUTH_TOKEN, token)
}

const logout = () => {
  const signinURL = getBaseURL('sign-in')
  clear()
  window.location.assign(`${signinURL}/logout/${PROJECT_TAG}`)
}

const clear = () => {
  localStorage.removeItem(AUTH_TOKEN)

}

const getUserIdFromToken = () => {
  const token = getAuthToken()
  const payload = JSON.parse(atob(token.split('.')[1]))
  return payload.sub
}

const getUserEmailFromToken = () => {
  const token = getAuthToken()
  const payload = JSON.parse(atob(token.split('.')[1]))
  return payload.email
}

export default {
  clear,
  getAuthToken,
  getUserEmailFromToken,
  getUserIdFromToken,
  isAuthenticated,
  login,
  logout
}
